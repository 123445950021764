#custom-navbar{
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #0C3E35E6;
}

#custom-navbar-cel{
    position: relative;
    width: 100%;
    top: 0;
    background-color: #0C3E35E6;
}

#nav-toggler {
    border: none;
    margin-right: 10px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0 !important;
}

.bt-container {
    display: flex;
}

.me-auto {
    margin-right: auto!important;
    width:100%;
}

.nav-link{
    color: #E8C6AD !important;
}
.nav-link:hover{
    color: #DD7876 !important;
}

#nav-bt-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media screen and (min-width: 991px){
#nav-bt-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}}


